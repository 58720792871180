.img-section-1-2 {
    background-image: url('../../../assets/conocenos_banner_2_1.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 600px;
    border-top: 4px solid #ce912f;
    border-bottom: 4px solid #ce912f;
}

.section2-carousel-container {
    background-color: #ce912f;
    border-radius: 10px;
    padding: 20px 40px;
    min-height: 200px !important;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: -180px;
}

.carousel-text {
    padding: 20px;
    font-size: 22px;
    color: #0e2127;
}

.btn-club-queso-2 {
    border: 4px solid #ce912f;
    background-color: #0f2c34 !important;
    border-radius: 10px;
    padding: 10px 40px;
    margin: 50px 0 120px 0;
    font-weight: bold;
    color: #ce912f !important;
    font-size: 22px;
}

.btn-info {
    color: #ce912f !important;
}

.btn-club-queso-2:hover {
    color: #ce922f !important;
    border-color: #ce922f !important;
}

.btn-club-queso-2:visited {
    color: #ce912f !important;
    border-color: #ce922f !important;
}

.second-box {
    background-image: url('../../../assets/home_background.jpg') !important;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .section2-carousel-container {
        margin-left: 0px;
        margin-right: 0px;
        margin-top: -280px;
    }
}