.custom-bg-header {
    background-image: url('../assets/header_background.jpg') !important;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    padding: 3vw 0;
}

.navbar-brand {
    font-size: 32px !important;
    font-weight: 100;
}

.navbar-logo {
    width: 290px;
}

.nav-item {
    margin-left: 40px;
    font-size: 24px;
    font-weight: bold;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff !important;
}

.nav-item .active {
    border-bottom: 2px solid rgb(196, 152, 56);
}

.navbar-title {
    font-size: 37px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

    .navbar-logo {
        width: 190px !important;
    }
}