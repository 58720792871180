.img-beneficio-section {
    width: 70%;
}

.btn-beneficio-section {
    background-color: #ce912f;
    color: #0e2127;
    border-radius: 10px;
    font-size: 24px !important;
    border: 0px;
    font-weight: bold;
    padding: 0 20px;
    text-decoration: none;   
}

.btn-beneficio-section:hover {
    color: #0e2127;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .img-beneficio-section {
        width: 100%;
    }
}