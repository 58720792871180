@media (max-width: 1200px) {
}

@media (max-width: 980px) {
  .title {
    font-size: 28px !important;
    text-align: center;
    margin: 20px 0 !important;
  }
  .cont-option {
    margin-top: 50px !important;
    width: 100% !important;
    text-align: center !important;
  }
  .cont-img {
    width: 100px !important;
    height: 100px !important;
  }
  .separte-title {
    font-size: 28px !important;
    margin: 20px !important;
  }
  .separte-img-icon {
    width: 100px !important;
    height: 100px !important;
    margin: 20px !important;
  }
  .separte-subtitle {
    width: 100% !important;
    padding: 0 !important;
    height: auto !important;
    overflow: auto !important;
    text-align: center !important;
    font-size: 25px !important;
  }
  .separte-subtitle p {
    width: auto !important;
    text-align: center !important;
    font-size: 20px !important;
  }
  .cont-img-item {
    padding: 0 !important;
    margin-bottom: 50px !important;
  }
  .bg-footer {
    border-top: 5px solid rgb(206, 145, 47) !important;
  }
  .footer-title {
    font-size: 20px !important;
  }
  .section1-carousel-container {
    min-height: 600px !important;
    padding: 25px !important;
    margin-top: -250px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: block;
  }
  .carousel-impacto {
    margin: 100px 0 !important;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 32px !important;
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption small {
    font-size: 20px !important;
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption {
    margin-bottom: 50px;
  }
}

/* tablets */
@media (max-width: 767px) {
  .navbar-title {
    font-size: 37px !important;
  }
  .title {
    font-size: 38px !important;
    text-align: center;
    margin: 20px 0 !important;
  }
  .carousel-home {
    margin: 40px 5px !important;
  }
  .carousel-item img {
    background-size: contain;
  }
  .cont-option {
    width: 100% !important;
    text-align: center !important;
  }
  .cont-img {
    width: 180px !important;
    height: 180px !important;
  }
  .separte-title {
    font-size: 37px !important;
    margin: 20px !important;
  }
  .separte-img-icon {
    width: 150px !important;
    height: 150px !important;
    margin: 20px !important;
  }
  .separte-subtitle {
    width: 100% !important;
    padding: 0 !important;
    height: auto !important;
    overflow: auto !important;
    text-align: center !important;
    font-size: 28px !important;
  }
  .separte-subtitle p {
    width: auto !important;
    text-align: center !important;
    font-size: 20px !important;
  }
  .suscripcion-title {
    font-size: 37px !important;
    margin: 20px 0 !important;
  }
  .cont-img-item {
    padding: 0 !important;
    margin-bottom: 50px !important;
  }
  .bg-footer {
    border-top: 5px solid rgb(206, 145, 47) !important;
  }
  .contacto, .tienda, .redes {
    margin: 20px 0 !important;
    text-align: center !important;
  }
  .section1-carousel-container {
    min-height: 600px !important;
    padding: 25px !important;
    margin-top: -400px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: block;
  }
  .carousel-impacto {
    margin: 40px 0 !important;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 20px !important;
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption small {
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption {
    margin-bottom: 20px;
  }
}

/* teléfonos */
@media (max-width: 480px) {
  .navbar-title {
    font-size: 37px !important;
  }
  .title {
    font-size: 28px !important;
    text-align: center;
    margin: 20px 0 !important;
  }
  .carousel-home {
    margin: 40px 5px !important;
  }
  .carousel-item img {
    background-size: contain;
  }
  .cont-option {
    width: 100% !important;
    text-align: center !important;
  }
  .cont-img {
    width: 100px !important;
    height: 100px !important;
  }
  .separte-title {
    font-size: 37px !important;
    margin: 20px !important;
  }
  .separte-img-icon {
    width: 150px !important;
    height: 150px !important;
    margin: 20px !important;
  }
  .separte-subtitle {
    width: 100% !important;
    padding: 0 !important;
    height: auto !important;
    overflow: auto !important;
    text-align: center !important;
    font-size: 28px !important;
  }
  .separte-subtitle p {
    width: auto !important;
    text-align: center !important;
    font-size: 20px !important;
  }
  .suscripcion-title {
    font-size: 37px !important;
    margin: 20px 0 !important;
  }
  .cont-img-item {
    padding: 0 !important;
    margin-bottom: 50px !important;
  }
  .bg-footer {
    border-top: 5px solid rgb(206, 145, 47) !important;
  }
  .contacto, .tienda, .redes {
    margin: 20px 0 !important;
    text-align: center !important;
  }
  .section1-carousel-container {
    min-height: 600px !important;
    padding: 25px !important;
    margin-top: -400px !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    display: block;
  }
  .carousel-impacto {
    margin: 40px 0 !important;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption h3 {
    font-size: 20px !important;
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption small {
    text-shadow: 5px 10px 10px #262626;
  }
  .carousel-impacto .carousel-inner .carousel-item .carousel-caption {
    margin-bottom: 20px;
  }
}
