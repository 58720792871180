.container-total {
    padding-right: 0;
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
}

.bg-suscripcion {
    background-image: url('../../../assets/home_3_background.jpg') !important;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
}

.img-item {
    width: 100%;
}

.content-section-4 {
    margin-top: 140px;
    margin-bottom: 140px;
}

.suscripcion-title-section-2 {
    border: 4px solid #ce912f;
    border-radius: 10px;
    color: #ce912f;
    font-weight: bold;
    padding: 10px;
    font-size: 46px;
    width: 60%;
    margin: 0 auto !important;
    background-color: #0e2127;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .content-section-4 {
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .text-content-4 {font-size: 22px !important;}
    .suscripcion-title-section-2 {
        width: 100% !important;
    }
}