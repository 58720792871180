.bg-section-2 {
    background-image: url('../../../assets/home_background.jpg') !important;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    padding: 1vw 0;
}

.title {
    margin-top: 15px !important;
    margin-left: 60px;
    font-size: 38px;
    margin-bottom: 0px !important;
    margin-top: 1vw;
    color: #0e2127;
}

.bold {
    font-weight: bold;
}

.cont-option {
    /* width: 70px; */
    margin-top: 10px;
}

.cont-img {
    /* width: 70px; */
    height: 45px;
}

.cont-text {
    font-size: 18px;
    color: #0e2127;
}

.plus-item {
    width: 20px;
    margin-top: 35px;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .cont-img {
        width: 40% !important;
        height: 40px !important;
    }
    .queso {
        width: 40% !important;
    }
    .mermelada {
        width: 20% !important;
    }
    .cont-text {
        font-size: 22px;
    }
}