.carousel-indicators [data-bs-target] {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-left: 10px;
}

.carousel-caption {
    left: 6%;
}

.carousel-indicators .active {
    background-color: rgb(196, 152, 56);
    width: 30px;
    height: 30px;
}

.carousel-inner .carousel-item .carousel-caption {
    top: 0;
    left: 70% !important;
    right: 0%;
}

.carousel-inner .carousel-item .carousel-caption .center.div {
    position: relative;
    width: 100% !important;
}

.carousel-inner .carousel-item .carousel-caption h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    font-weight: bold !important;
    font-size: 2.8vw;
    color: rgb(196, 152, 56);
}

.carousel-inner .carousel-item .carousel-caption .btn {
    border-radius: 10px !important;
    padding-left: 30px;
    padding-right: 30px;
}

.carousel-home {
    /* max-height: 37vw !important; */
    /* overflow: hidden; */
    /* margin: 50px 70px; */
    border-top: 4px solid rgb(196, 154, 56);
    border-bottom: 6px solid rgb(196, 152, 56);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .carousel-home {
        margin: 0px !important;
    }
    .carousel-indicators [data-bs-target] {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-left: 10px;
    }

    .carousel-indicators .active {
        background-color: rgb(196, 152, 56);
        width: 10px;
        height: 10px;
    }
}