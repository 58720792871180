.impacto-title {
    font-size: 32px !important;
}

.img-impacto-section-1-1 {
    position: relative;
    height: 100vh;
    border-top: 4px solid #ce912f;
    border-bottom: 4px solid #ce912f;
    margin-bottom: 200px;
}

iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.box-impacto-section-1 {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ce912f;
    padding: 10px 20px;
    border-radius: 10px;
    width: 400px;
    font-weight: bold;
    font-size: 22px;
    z-index: 1; /* Colocamos el div por encima del video */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .box-impacto-section-1 {
        width: 250px;
        margin: 0 auto;
        /* margin-top: -130px; */
    }
}

@media (min-aspect-ratio: 16/9) {
    .video-container iframe {
        /* height = 100 * (9 / 16) = 56.25 */
        height: 56.25vw;
    }
}

@media (max-aspect-ratio: 16/9) {
    .video-container iframe {
        /* width = 100 / (9 / 16) = 177.777777 */
        width: 177.78vh;
    }
}