@import url(//db.onlinewebfonts.com/c/7e680ef2745d9d3ab9c23c607414f7eb?family=ALSSchlangesans-Black);
@import url(//db.onlinewebfonts.com/c/0f061393ca256198aa7f3a9c1bfb370a?family=Brocha);

/* @font-face {
  font-family: "ALSSchlangesans-Black";
  src: url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.eot");
  src: url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/7e680ef2745d9d3ab9c23c607414f7eb.svg#ALSSchlangesans-Black")
      format("svg");
} */

/* @font-face {
  font-family: "Brocha";
  src: url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.eot");
  src: url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/0f061393ca256198aa7f3a9c1bfb370a.svg#Brocha")
      format("svg");
} */

@font-face {
  font-family: "Candarai";
  src: url("fonts/candarai.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Candaraz";
  src: url("fonts/candaraz.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Candara";
  src: url("fonts/Candara.ttf");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Brocha",
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  font-family: "Candara" !important;
  font-style: normal;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

/* h1, h2, h3, h4, h5 {
  font-family: "Brocha";
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
