.bg-section-3 {
    background-image: url('../../../assets/home_2_background.jpg') !important;
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    padding: 1vw 0;
}

.section {
    padding: 3vw 0;
}

.vertial-aligment {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}

.separte-title {
    color: #ce912f;
    font-size: 46px;
    padding: 20px;
    background-color: #0e2127;
    border: 4px solid #ce912f;
    border-radius: 10px;
}

.separte-img-icon {
    width: 200px;
    height: 200px;
}

.separte-number {
    font-size: 68px;
    color: #ce912f;
}

.separte-subtitle {
    font-size: 34px;
}

.separte-line {
    border-top: 0.1vw solid #ce912f;
}

.content-section-3 {
    border: solid #ce912f;
    border-width: 0 4px 4px;
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin-top: -90px;
    padding-top: 80px;
}

.content-section-3:before,
.content-section-3:after {
    position: absolute;
    background: #ce912f;
    content: '';
    height: 4px;
    width: 16px;
    top: 0;
    left: 0;
}

.content-section-3:after {
    left: auto;
    right: 0;
}

.link-orange {
    color: #ce912f; 
    text-decoration: none; 
    transition: all 0.3s;
}

.link-orange::hover {
    color: #ce912f;
}

.number {
    border-right: 2px solid #fff;
}

.number-content-section-3 {
    font-size: 62px;
    font-weight: bold;
    text-align: center;
    padding-top: 20px;
    margin-left: 30px;
}

.content-box {
    padding-left: 30px;
}

.title-content-section-3 {
    font-size: 34px;
    font-weight: bold;
    text-align: start;
    margin: 0 !important;
    /* background-color: yellow; */
}

.img-content-section-3 img {
    width: 70%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .number {border: 0px !important;}
    .number-content-section-3 {
        font-size: 62px;
        font-weight: bold;
        text-align: center;
        padding: 0px !important;
        margin: 0px !important;
    }
    .title-content-section-3 {
        text-align: center !important;
    }
    .img-content-section-3 {
        text-align: center !important;
    }
    .img-content-section-3 img {
        width: 40%;
        margin: 0 auto !important;
        padding-top: 90px;
    }
    .text-content-section-3 {
        font-size: 22px;
        text-align: center;
        margin-top: 10px;
    }
}