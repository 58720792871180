.img-impacto-section-1-2 {
    background-image: url('../../../assets/RFV_1408.jpg') !important;
    background-position: top !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 600px;
    border-top: 4px solid #ce912f;
    border-bottom: 4px solid #ce912f;
}

.quote-impacto-section-1-2 {
    font-size: 32px;
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .quote-impacto-section-1-2 {
        font-size: 24px;
    }
}