.bg-footer {
    background-color: #ce912f;
    color: #0e2127;
    padding: 5px 0 !important;
}

.logo-cerrito {
    width: 100px;
}

.social-icon {
    width: 40px;
}

p.footer-title {
    margin: 0 !important;
    padding: 0 !important;
    font-weight: bold !important;
    color: #0e2127 !important;
}

.redes {
    padding-top: 20px;
}